import React from 'react';
import ReactDOM from 'react-dom';
import Cookies from 'js-cookie';
import './assets/scss/style.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import setAuthorizationToken from './services/authorization';
import store from './store';
import { AUTH_CLIENT, GET_CLIENT } from './actions/client/type';
import { getClient } from './actions/client';
import { errorNotif, initNotif } from './actions/notifications';

const token = Cookies.get('sb_token');
if (token) {
  setAuthorizationToken(token);
  store.dispatch({
    type: AUTH_CLIENT
  });
  (async () => {
    try {
      const client = await getClient();
      store.dispatch({
        type: GET_CLIENT,
        client
      });
    } catch (e) {
      store.dispatch(errorNotif(e));
      store.dispatch(initNotif());
      throw (e);
    }
  })();
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();