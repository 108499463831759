import React from "react";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import './Sidenav.scss';
import Logo from '../../assets/images/logo.png';
import CloudComputing from '../../assets/images/icons/cloud-computing.png';
import routes from '../../routes';
import { closeMenu } from '../../actions/menu';

const links = [
  { path: routes.home, name: 'Upload', icon: CloudComputing },
];

const Sidenav = (props) => {
  const { isOpen, closeMenu } = props;

  return (
    <aside className="sidenav" style={isOpen ? { display: 'block' } : null} >
      <img className="logo" src={Logo} alt="smartbottle" />

      <ul>
        {links.map((link) =>
          <li key={link.path}>
            <Link onClick={closeMenu} to={link.path}>
              {link.icon !== null && link.icon !== undefined
                ? <img src={link.icon} alt={link.name} />
                : null
              }
              <span>{link.name}</span>
            </Link>
          </li>
        )}
      </ul>
    </aside >
  )
}

const mapStateToProps = ({ menuReducer }) => {
  return {
    isOpen: menuReducer.isOpen
  }
}

export default connect(mapStateToProps, {
  closeMenu
})(withRouter(Sidenav));