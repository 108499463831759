import React, { useCallback, useEffect } from "react";
import { connect } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import './Home.scss';
import Sidenav from "../../components/Sidenav/Sidenav";
import AsideFile from "../../components/AsideFile/AsideFile";
import addIcon from '../../assets/images/icons/add.png';
import uploadImg from '../../assets/images/upload.png';
import { getClientFiles, addClientFiles } from '../../actions/client';

const Home = (props) => {
  const { client, files, getClientFiles, addClientFiles, load } = props;

  useEffect(() => {
    if (client !== null) {
      getClientFiles(client.data.id);
    }
  }, [client, getClientFiles]);

  const onDrop = useCallback(acceptedFiles => {
    addClientFiles({ acceptedFiles, client });
  }, [addClientFiles, client]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="main-content d-flex align-center justify-center">
      <Sidenav />
      <div className="main-content-container d-flex align-center justify-center is-card">
        {load
          ? <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          : null
        }
        <div className="upload-content-container">
          <div {...getRootProps()}>
            <div className="drag-and-drop-container">
              <input {...getInputProps({ capture: false, accept: 'image/*,.pdf,.mp4' })} />
              <img src={uploadImg} alt="drag and drop files" />
              <div>
                {
                  isDragActive
                    ? 'Déposez les fichiers ici ...'
                    : 'Faites glisser et déposez les fichiers ici'
                }
              </div>
              <div className="txt-separator">ou</div>
            </div>
            <div>
              <button className="btn has-icon"><img src={addIcon} alt="add files" />Ajouter des fichiers</button>
            </div>
          </div>
        </div>
      </div>
      <AsideFile files={files} />
    </div>
  )
}

const mapStateToProps = ({ clientReducer }) => {
  return {
    client: clientReducer.client,
    files: clientReducer.files,
    load: clientReducer.load,
  }
}

export default connect(mapStateToProps, {
  getClientFiles,
  addClientFiles
})(Home);