import React from "react";
import './Login.scss';
import Sidenav from "../../components/Sidenav/Sidenav";
import useForm from "react-hook-form";
import routes from '../../routes';
import { connect } from 'react-redux';
import { authClient } from '../../actions/client';

const Login = (props) => {
  const { authClient, history } = props;
  const { handleSubmit, register, errors } = useForm();

  const onSubmit = async ({ email, password }) => {
    await authClient({ password, email });
    history.push(routes.home);
  }

  return (
    <div className="main-content d-flex align-center justify-center">
      <Sidenav />
      <div className="main-content-container d-flex align-center justify-center is-card">
        <div className="login-container">
          <h2>Authentification</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="input-container">
              <input
                name="email"
                type="email"
                placeholder="Email"
                ref={register({
                  required: 'Ce champ doit être rempli',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Adresse email invalide"
                  }
                })}
              />
              <div className="error-message">
                {errors.email && errors.email.message}
              </div>
            </div>

            <div className="input-container">
              <input
                name="password"
                type="password"
                placeholder="Mot de passe"
                ref={register({
                  required: 'Ce champ doit être rempli',
                })}
              />
              <div className="error-message">
                {errors.password && errors.password.message}
              </div>
            </div>

            <button className="btn" type="submit">Connexion</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default connect(null, {
  authClient
})(Login);