import { GET_CLIENT, GET_FILES, ADD_FILES, AUTH_CLIENT, LOAD } from '../actions/client/type';

const initialState = {
  client: null,
  authenticated: false,
  files: [],
  load: false
};

const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLIENT:
      return {
        ...state,
        client: action.client,
        authenticated: true
      };
    case AUTH_CLIENT:
      return {
        ...state,
        authenticated: true
      }
    case GET_FILES:
      return {
        ...state,
        files: action.files
      };
    case ADD_FILES:
      return {
        ...state,
        files: action.files.concat(state.files)
      };
    case LOAD:
      return {
        ...state,
        load: action.payload
      };
    default:
      return state;
  }
}

export default clientReducer;