const setViticulteurApiUrl = () => {
  let url;
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      url = 'https://api-viticulteur.smartbottle.wine';
      break;
    case 'test':
      url = 'https://test.api-viticulteur.smartbottle.wine';
      break;
    case 'local':
      url = 'http://localhost:3003';
      break;
    default:
      url = 'https://api-viticulteur.smartbottle.wine';
      break;
  }
  return url;
}
export const API_UPLOAD = 'https://api-upload-cloud-oaqthzkpda-ew.a.run.app';
export const API_DB = 'https://api-db-oaqthzkpda-ew.a.run.app';
export const SSO_URL = 'https://api-sso-oaqthzkpda-ew.a.run.app/';