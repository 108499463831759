import { combineReducers } from 'redux';
import clientReducer from './clientReducer';
import notificationReducer from './notificationReducer';
import menuReducer from './menuReducer';

const reducers = combineReducers({
  clientReducer,
  notificationReducer,
  menuReducer
});

export default reducers;
