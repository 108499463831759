import axios from 'axios';
import Cookies from 'js-cookie';
import { SSO_URL, API_DB, API_UPLOAD } from '../../config'
import { GET_CLIENT, GET_FILES, ADD_FILES, LOAD } from './type';
import { clearFileData } from '../../services/document';
import { errorNotif, successNotif, initNotif } from '../notifications';
import setAuthorizationToken from '../../services/authorization';

export const authClient = ({ email, password }) => {
  return async dispatch => {
    try {
      // mail test: email@email.com
      // password test: smartbottle_testclientviticulteur
      const { data } = await axios.post(`${SSO_URL}/clients/auth/token`, {
        email,
        password
      });

      if (!data.success) {
        throw (data.message);
      }

      Cookies.set('sb_token', data.token, { expires: 7 });
      setAuthorizationToken(data.token);
      const client = await getClient();
      dispatch({
        type: GET_CLIENT,
        client
      });
      dispatch(successNotif('Connexion réussi'));
    } catch (e) {
      dispatch(errorNotif(e));
      dispatch(initNotif());
      throw (e);
    }
  }
};

export const getClient = async () => {
  try {
    
    const { data } = await axios.get(`${API_DB}/customers/me`);
    return data;
  } catch (e) {
    throw (e);
  }
};

export const getClientFiles = (id) => {
  return async dispatch => {
    try {
      const { data } = await axios.get(`${API_DB}/customers/${id}/documents`);
      console.log(data);
      
      
      const files = data.data.map(file => clearFileData(file));
      dispatch({
        type: GET_FILES,
        files
      });
    } catch (e) {
      dispatch(errorNotif(e));
      dispatch(initNotif());
      throw (e);
    }
  }
}

export const addClientFiles = ({ acceptedFiles, client }) => {
  return async dispatch => {
    try {
      dispatch({
        type: LOAD,
        payload: true
      });
      const formData = new FormData();
      formData.append('client', JSON.stringify({
        id: client.data.id,
        company_name: client.data.company_name,
      }));
      acceptedFiles.map(file => formData.append('files', file));
      console.log(formData);
      
      const res = await axios.post(`${API_UPLOAD}/document/clients`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (!res.data.success) throw (res.data.message);
      const uploadedFiles = res.data.files.map(file => clearFileData(file));
      const totalFiles = uploadedFiles.length;
      const successMessage = totalFiles > 1 ? 'fichiers envoyés' : 'fichier envoyé';
      dispatch({
        type: LOAD,
        payload: false
      });
      dispatch(successNotif(`${totalFiles} ${successMessage}`));
      dispatch(initNotif());
      dispatch({
        type: ADD_FILES,
        files: uploadedFiles
      });
    } catch (e) {
      dispatch({
        type: LOAD,
        payload: false
      });
      dispatch(errorNotif(e));
      dispatch(initNotif());
      throw (e);
    }
  }
}