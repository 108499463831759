import pdfImg from '../assets/images/pdf.png';
import docImg from '../assets/images/doc.png';
import pngImg from '../assets/images/png.png';
import { API_UPLOAD } from '../config';

export const getFileExtImg = (ext) => {
  let img = null;
  switch (ext) {
    case 'pdf':
      img = pdfImg;
      break;
    case 'doc':
    case 'docx':
      img = docImg;
      break;
    case 'png':
    default:
      img = pngImg;
      break;
  }
  return img;
}

export const clearFileData = (file) => {
  return {
    name: file.originalname
      ? file.originalname
      : file.original_name
        ? file.original_name
        : file.filename,
    url: file.path,
    ext: file.filename.split('.')[1],
    filename: file.filename,
    new: true
  }
};