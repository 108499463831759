import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import './MobileMenu.scss';
import burger from '../../assets/images/icons/open-menu.svg';
import close from '../../assets/images/icons/close.svg';
import { openMenu, closeMenu } from '../../actions/menu';

const MobileMenu = (props) => {
  const { openMenu, closeMenu, isOpen } = props;
  const [iconMenu, setIconMenu] = useState(burger);

  useEffect(() => {
    if (isOpen) {
      setIconMenu(close);
    } else {
      setIconMenu(burger);
    }
  }, [isOpen]);

  const toggleMenu = () => {
    if (isOpen) {
      closeMenu();
    } else {
      openMenu();
    }
  };

  return (
    <div className="mobile-menu" onClick={toggleMenu}>
      <img src={iconMenu} alt="menu" />
    </div>
  )
};

const mapStateToProps = ({ menuReducer }) => {
  return {
    isOpen: menuReducer.isOpen
  }
};

export default connect(mapStateToProps, {
  closeMenu,
  openMenu
})(MobileMenu);