import { ERROR_NOTIF, SUCCESS_NOTIF, INIT_NOTIF } from '../actions/notifications/type';

const initialState = {
  message: '',
  type: null
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_NOTIF: {
      return initialState;
    }
    case SUCCESS_NOTIF:
      return {
        type: 'success',
        message: action.message
      };
    case ERROR_NOTIF:
      return {
        type: 'error',
        message: action.message
      };
    default:
      return state;
  }
}

export default notificationReducer;