import { SUCCESS_NOTIF, ERROR_NOTIF, INIT_NOTIF } from "./type";

export function successNotif(notif) {
  return {
    type: SUCCESS_NOTIF,
    message: notif
  };
}

export function initNotif() {
  return {
    type: INIT_NOTIF
  };
}

export function errorNotif(notif) {
  return {
    type: ERROR_NOTIF,
    message: notif
  };
}