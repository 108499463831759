import React, { useState } from "react";
import './AsideFile.scss';
import { getFileExtImg } from '../../services/document';

const AsideFile = (props) => {
  const [searchString, setSearchString] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const { files } = props;

  const handleInputChange = (e) => {
    const { value } = e.target;
    setSearchString(value);
    setSearchResults(files.filter(({ name }) => {
      return name.toLowerCase().includes(value.toLowerCase());
    }));
  }

  const filesArray = searchString !== '' ? searchResults : files;

  return (
    <div className="aside-content-container is-card">
      <h2>Mes fichiers</h2>
      <input name="search" value={searchString} onChange={handleInputChange} placeholder="Rechercher dans mes fichiers" />
      <ul>
        {filesArray.map(file =>
          <li key={file.filename}>
            <a className="d-flex"
              target="_blank"
              rel="noopener noreferrer"
              href={file.url}>
              <img src={getFileExtImg(file.ext)} alt={file.name !== undefined ? file.name : file.filename} />
              <div>
                {file.name !== undefined ? file.name : file.filename}
                {file.new}
              </div>
            </a>
          </li>
        )}
      </ul>
    </div>
  )
}

export default AsideFile;